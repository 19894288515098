/**
 * Created by razial on 11.03.2025.
 */

var Homefix = function () {
    cleverapps.EventEmitter.call(this);

    this.location = cleverapps.meta.getSelectedLocation();

    Homefix.currentHomefix = this;

    cleverapps.config.saveUnitsEnabled = false;

    var configData = bundles["location_" + this.location.locationId].jsons.data_json.getJson();

    this.config = configData;

    this.furniture = configData.items.map(function (data) {
        return new Furniture(this, data);
    }, this);

    this.tasks = new HomeTasks(this);

    var saveData = this.loadSave();

    this.furnitureId = saveData.furnitureId === undefined ? this.firstFurnitureId() : saveData.furnitureId;
    this.storedUnits = saveData.units || {};
    this.storedFogs = saveData.fogs || [];
    this.storedLibrary = saveData.library || {};

    UnitsLibraryHelper.Switch(this.location.locationId);

    var mapAdapter = new HomefixMap2dAdapter(this);

    var mapData = bundles["location_" + this.location.locationId].jsons.map_json.getJson();

    this.map = new Map2d(mapAdapter, mapData.map, {
        tiles: mapData.tiles,
        units: mapData.units,
        visibleBox: mapData.visibleBox,
        regions: mapData.regions,
        decorators: mapData.decorators,
        terrains: mapData.terrains,
        field: mapData.field,
        families: mapData.families
    });

    this.map.onUnitAvailableListener = function (unit) {
        cleverapps.unitsLibrary.openUnit(unit);
    };

    if (!cleverapps.config.editorMode) {
        this.map.loadUnits();
        this.map.loadFogs();

        this.map.fogs.initStage();

        cleverapps.config.saveUnitsEnabled = true;

        if (this.map.fogs.blocks.fog0) {
            this.map.fogs.blocks.fog0.unlock();
        }

        if (!this.tasks.actives) {
            this.tasks.setActives();
        }
    } else {
        Map2d.InsertDefaults(this.map, this.map.field);
    }

    this.map.counter.registerStage(1, this.map.fogs.calcFogStatesStage.bind(this.map.fogs));
    this.map.counter.registerStage(2, this.getNewTaskStage.bind(this));
    this.map.counter.registerStage(3, this.moveNextLocationStage.bind(this));
    this.map.counter.registerStage(4, this.map.playCinematics.bind(this.map));

    if (!cleverapps.config.adminMode && !cleverapps.config.editorMode) {
        this.updateAvailableUnits();
    }
};

Homefix.prototype = Object.create(cleverapps.EventEmitter.prototype);
Homefix.prototype.constructor = Location;

Homefix.prototype.stop = function () {
    Map2d.currentMap.stop();
    Map2d.currentMap = undefined;
};

Homefix.prototype.loadSave = function () {
    return cleverapps.dataLoader.load(DataLoader.TYPES.LOCATION + this.location.slot) || {};
};

Homefix.prototype.storeSave = function () {
    cleverapps.dataLoader.save(DataLoader.TYPES.LOCATION + this.location.slot, this.getInfo());

    cleverapps.synchronizer.addUpdateTask("locations" + this.location.slot);
};

Homefix.prototype.getInfo = function () {
    return {
        furnitureId: this.furnitureId !== undefined ? this.furnitureId : this.firstFurnitureId(),
        units: this.storedUnits || {},
        fogs: this.storedFogs || [],
        library: this.storedLibrary || {}
    };
};

Homefix.prototype.load = function (data) {
    data = data || {};

    this.furnitureId = data.furnitureId === undefined ? this.firstFurnitureId() : data.furnitureId;
    this.storedUnits = data.units || {};
    this.storedFogs = data.fogs || [];
    this.storedLibrary = data.library || {};
};

Homefix.prototype.updateAvailableUnits = function () {
    this.map.listAvailableUnits().forEach(function (unit) {
        var climbableAction = Map2dDecorators.getClimbableAction(unit, true);
        if (climbableAction) {
            climbableAction();
        }
    });
};

Homefix.prototype.handleMakeChoiceWindow = function (component) {
    cleverapps.focusManager.display({
        focus: "MakeChoiceWindow",
        actions: [
            function (f) {
                this.showUnit(component.unit, f);
            }.bind(this),
            function (f) {
                new MakeChoiceWindow(component);
                cleverapps.focusManager.onceNoWindowsListener = f;
            },
            function (f) {
                var wasChanged = !component.unit.onGetView();
                this.unShowUnit(!wasChanged);
                f();
            }.bind(this)
        ]
    });
};

Homefix.prototype.firstFurnitureId = function () {
    return -1;
};

Homefix.prototype.getFurniture = function () {
    return this.furniture;
};

Homefix.prototype.getCurrentFurnitureId = function () {
    return this.furnitureId;
};

Homefix.prototype.setCurrentFurnitureId = function (newId) {
    this.furnitureId = newId;
    this.storeSave();
};

Homefix.prototype.getFurnitureById = function (id) {
    var furniture = this.getFurniture();

    for (var i in furniture) {
        if (furniture[i].id === id) {
            return furniture[i];
        }
    }
    return undefined;
};

Homefix.prototype.getFurnitureByName = function (name) {
    var furniture = this.getFurniture();

    for (var i in furniture) {
        if (furniture[i].name === name) {
            return furniture[i];
        }
    }
    return undefined;
};

Homefix.prototype.getMaxFurniture = function () {
    return this.getFurnitureById(this.getCurrentFurnitureId());
};

Homefix.prototype.saveUnitsLibrary = function (unitsLibrary) {
    this.storedLibrary = unitsLibrary;
    this.storeSave();
};

Homefix.prototype.getNextId = function () {
    var furniture = this.getFurniture();
    var currentFurnitureId = this.getCurrentFurnitureId();

    for (var i = 0; i < furniture.length; i++) {
        var currentFurniture = furniture[i];

        if (currentFurniture.id === currentFurnitureId) {
            var nextFurniture = furniture[i + 1];
            return nextFurniture ? nextFurniture.id : currentFurnitureId + 1;
        }
    }
};

Homefix.prototype.giveNewTask = function (f, furniture) {
    this.tasks.add(furniture);
    if (furniture.id > this.getCurrentFurnitureId()) {
        this.setCurrentFurnitureId(furniture.id);
    }

    this.newTask(furniture, f);
};

Homefix.prototype.getFurnitureToUpgrade = function () {
    for (var id in this.tasks.actives) {
        if (this.tasks.actives[id].canBeUpgraded()) {
            return this.tasks.actives[id];
        }
    }

    return undefined;
};

Homefix.prototype.getFurnitureToOpen = function () {
    if (!cleverapps.config.saveUnitsEnabled) {
        return [];
    }
    return this.getFurniture().filter(function (furniture) {
        return furniture.readyToOpen() && !this.tasks.isActive(furniture);
    }.bind(this));
};

Homefix.prototype.getActiveFurniture = function () {
    return this.getFurniture().filter(function (furniture) {
        return furniture.readyToOpen() && furniture.id <= this.getCurrentFurnitureId();
    }.bind(this));
};

Homefix.prototype.moveNext = function (furniture, f) {
    var cost = furniture.getInfo().cost;
    cleverapps.meta.logic.changeStars(-cost);

    if (!furniture.nextStage()) {
        this.tasks.onFurnitureCompleted(furniture);
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.METHA_ITEM + "_" + cleverapps.meta.selectedLocationId() + "_" + furniture.id);
    }

    this.changeStage(furniture, f);
};

Homefix.prototype.animateNewTask = function (furniture, f) {
    this.trigger("animateNewTask", furniture, f);
};

Homefix.prototype.createDialogue = function (dialogueName) {
    var dialogue = this.config.dialogues[dialogueName];
    if (!dialogue) {
        return undefined;
    }

    return new Dialogue(dialogue, {
        autoClose: true,
        showUp: true
    });
};

Homefix.prototype.getNewTaskStage = function () {
    if (!cleverapps.config.saveUnitsEnabled) {
        return;
    }

    var toOpen = this.getFurnitureToOpen();
    if (!toOpen.length) {
        return;
    }

    cleverapps.focusManager.display({
        focus: "homefixNewTask",
        actions: toOpen.map(function (furniture) {
            return function (f) {
                this.giveNewTask(f, furniture);
            }.bind(this);
        }.bind(this))
    });
};

Homefix.prototype.moveNextLocationStage = function () {
    if (!this.location.isCompleted() || this.location.locationId !== cleverapps.meta.selectedLocationId()) {
        return;
    }

    cleverapps.focusManager.display({
        focus: "homeMoveNextLocation",
        actions: [
            function (f) {
                cleverapps.meta.moveNextLocation();
                f();
            },
            function (f) {
                if (cleverapps.meta.isCompleted()) {
                    new AllDoneWindow();
                    cleverapps.focusManager.onceNoWindowsListener = f;
                    return;
                }

                new TravelMapWindow();
                cleverapps.focusManager.onceNoWindowsListener = f;
            },
            function (f) {
                if (cleverapps.meta.isCompleted()) {
                    f();
                    return;
                }

                cleverapps.meta.gotoMainLocation(f);
            }
        ]
    });
};

Homefix.prototype.changeStage = function (furniture, f) {
    var actions = [
        function (f) {
            this.showUnit(furniture.unit, f);
        }.bind(this),

        function (f) {
            this.moveNextUnitStage(furniture.unit, f);
        }.bind(this),

        function (f) {
            if (this.shownUnit && this.shownUnit !== furniture.unit) {
                this.showUnit(furniture.unit, f, true);
            } else {
                f();
            }
        }.bind(this),

        function (f) {
            var component = furniture.getChoiceComponent();
            if (component) {
                new MakeChoiceWindow(component);
                cleverapps.focusManager.onceNoWindowsListener = function () {
                    f();
                };
            } else {
                f();
            }
        },

        function (f) {
            if (this.location.isCompleted()) {
                var dialogue = this.createDialogue("Final");
                dialogue.on("afterClose", f);
                new DialogueView(dialogue);
            } else {
                f();
            }
        }.bind(this)
    ];

    if (!furniture.isComplete()) {
        actions = actions.concat([
            function (f) {
                this.newTask(furniture, f);
            }.bind(this)
        ]);
    } else {
        actions = actions.concat([
            function (f) {
                this.unShowUnit();
                f();
            }.bind(this)
        ]);
    }

    cleverapps.focusManager.compound(f, actions);
};

Homefix.prototype.showUnit = function (unit, callback, silent) {
    if (!unit) {
        callback();
        return;
    }

    this.shownUnit = unit;

    new ActionPlayer([
        function (f) {
            var sceneHeight = cleverapps.resolution.getSceneSize().height - cleverapps.resolution.getSafePadding().bottom;

            Map2dScroller.currentScroller.zoomIn(unit, {
                zoom: unit.findComponent(Invisible) ? 0.8 : 2,
                padding: {
                    top: 60,
                    bottom: sceneHeight * 0.4,
                    right: 60,
                    left: 60
                },
                centerOffset: {
                    x: 0,
                    y: unit.findComponent(Invisible) ? 0 : sceneHeight / 8
                },
                duration: silent && 0,
                callback: f
            });
        },

        function (f) {
            if (Homefix.needHighlight(unit)) {
                var highlighter = this.map.onDiscoverMapView().highlighter;
                if (highlighter) {
                    highlighter.addUnit(unit);
                    f();
                } else {
                    this.map.highlightUnit(unit, f, { spotlight: true, ground: false });
                }
            } else {
                f();
            }
        }.bind(this)
    ]).play(callback);
};

Homefix.prototype.moveNextUnitStage = function (unit, f) {
    if (!unit) {
        f();
        return;
    }

    this.map.fogs.wantsCalcFogStates = true;

    var throwaway = unit.findComponent(Throwaway);

    if (throwaway) {
        delete this.shownUnit;
        throwaway.animateDestroy(f);
        return;
    }

    var fixable = unit.findComponent(Fixable);

    if (fixable) {
        fixable.updateStage(f);
    }
};

Homefix.prototype.newTask = function (furniture, f) {
    cleverapps.focusManager.compound(f, [
        function (f) {
            this.showUnit(furniture.unit, f);
        }.bind(this),

        function (f) {
            this.showStageDialogue(furniture, f);
        }.bind(this),

        function (f) {
            this.unShowUnit();
            f();
        }.bind(this),

        function (f) {
            this.animateNewTask(furniture, f);
        }.bind(this)
    ]);
};

Homefix.prototype.showStageDialogue = function (furniture, f) {
    var furnitureInfo = furniture.getInfo();

    if (!furnitureInfo) {
        cleverapps.throwAsync("no furniture info");
        f();
        return;
    }

    var dialogue = this.createDialogue(furnitureInfo.dialogue);
    if (!dialogue) {
        f();
        return;
    }

    var regionControl = furniture.unit.findComponent(RegionControl);
    if (regionControl) {
        dialogue.on("showUp", function () {
            regionControl.animateHover();
            dialogue.on("beforeClose", regionControl.stopAnimateHover.bind(regionControl));
        });
    }

    dialogue.on("afterClose", f);

    new DialogueView(dialogue);
};

Homefix.prototype.unShowUnit = function (withZoomOut) {
    delete this.shownUnit;

    this.map.unhighlightUnit();

    if (withZoomOut) {
        Map2dScroller.currentScroller.zoomOut();
    } else {
        Map2dScroller.currentScroller.cancelZoomOut();
    }
};

Homefix.prototype.showUp = function (f) {
    if (this.location.locationId !== cleverapps.meta.selectedLocationId()) {
        f();
        return;
    }

    cleverapps.focusManager.compound(f, [
        function (f) {
            cleverapps.timeouts.setTimeout(f, 500);
        },

        function (f) {
            cleverapps.restoreProgress.update();
            f();
        },

        function (f) {
            var furniture = this.getMaxFurniture();
            if (furniture.id === this.firstFurnitureId()) {
                if (furniture.canBeUpgraded()) {
                    this.moveNext(furniture, f);
                } else if (!this.forcedAlready) {
                    this.forcedAlready = true;

                    cleverapps.focusManager.compound(f, [
                        function (f) {
                            this.showUnit(furniture.unit, f);
                        }.bind(this),

                        function (f) {
                            this.showStageDialogue(furniture, f);
                        }.bind(this),

                        function (f) {
                            this.unShowUnit();
                            cleverapps.timeouts.setTimeout(f, 0);
                        }.bind(this),

                        function (f) {
                            cleverapps.meta.wantsToPlay(f);
                        }
                    ]);
                } else {
                    f();
                }
            } else if (this.getFurnitureToUpgrade()) {
                new HomeTasksWindow();
                cleverapps.focusManager.onceNoWindowsListener = f;
            } else {
                f();
            }
        }.bind(this)
    ]);
};

Homefix.GetFurnitureProgress = function (level, furnitureIndex, currentStage) { /// ?
    var data = {
        furnitureId: Homefix.currentHomefix.furniture[furnitureIndex].id,
        stars: cleverapps.meta.logic.stars,
        items: {}
    };

    Homefix.currentHomefix.furniture.forEach(function (furniture, index) {
        var choice;
        var stage = 0;
        if (index < furnitureIndex) {
            if (furniture.hasChoices()) {
                choice = furniture.choice || 0;
            }
            stage = furniture.amountStages();
        } else if (index === furnitureIndex) {
            stage = currentStage;
        }

        data.items[furniture.id] = {
            choice: choice,
            stage: stage
        };
    });

    return data;
};

Homefix.needHighlight = function (unit) {
    if (unit.findComponent(Invisible)) {
        return false;
    }

    var multiCell = unit.findComponent(MultiCell);
    if (multiCell) {
        var size = multiCell.getSize();
        if (size.width > 2 && size.height > 2) {
            return false;
        }
    }

    return true;
};

if (cleverapps.config.debugMode) {
    Homefix.findDuplicateAndMissingUnits = function () {
        var codes = Map2d.currentMap.fogs.listFakeUnits(function () {
            return true;
        }).map(function (unit) {
            return unit.code;
        });

        var seen = {};
        var duplicates = [];

        codes.forEach(function (code) {
            if (seen[code]) {
                duplicates.push(code);
            } else {
                seen[code] = true;
            }
        });

        return {
            missing: cleverapps.substract(Families.codes, codes),
            duplicates: duplicates
        };
    };
}

CustomSyncers.registerBySlots("locations", function (slot) {
    return cleverapps.dataLoader.load(DataLoader.TYPES.LOCATION + slot) || {};
}, function (slot, serverData) {
    cleverapps.dataLoader.save(DataLoader.TYPES.LOCATION + slot, serverData);
});
